import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

// all images
import one from '../../images/demoImages/one.png'
import two from '../../images/demoImages/two.png'
import three from '../../images/demoImages/three.png'
import four from '../../images/demoImages/four.png'
import six from '../../images/demoImages/six.png'
import seven from '../../images/demoImages/seven.png'
import eight from '../../images/demoImages/eight.png'
import nine from '../../images/demoImages/nine.png'
import asd from '../../images/demoImages/asd.png'
import asdd from '../../images/demoImages/asdd.png'

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/2 w-full`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-128 rounded`
]);
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({open,setOpen,setImg}) => {
  const blogPosts = [
    {
      imageSrc: one,
      title: "A dark mode view of the matches"
    },
    {
      imageSrc:two,
      title: "Create a match pop-up"
    },
    {
      imageSrc: three,
      title: "Edit a match pop-up"
    },
    {
      imageSrc: four,
      title: "View of team announcements"
    },
    {
      imageSrc: six,
      title: "A poll chart"
    },
    {
      imageSrc: seven,
      title: "Announcements home page"
    },
    {
      imageSrc: eight,
      title: "A light mode view of the matches"
    },
    {
      imageSrc: nine,
      title: "App settings"
    },
    {
      imageSrc: asd,
      title: "No announcements yet"
    },
    {
      imageSrc: asdd,
      title: "Team ladder with positions, names, and contact information"
    }
  ];
  return (
      <>
    <Container id={"demos"}>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>View Demos</HeadingTitle>
          <HeadingDescription>Here are some pictures of the app being used. Click on the image to see it full-screen.</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image style={{borderRadius: 15}} onClick={() => {
                  setOpen(true)
                  setImg(post.imageSrc)
                }} imageSrc={post.imageSrc} />
                <Title>{post.title}</Title>
              </Card>
            </Column>
          ))}
        </ThreeColumn>

        {/*<div style={{marginTop: 50}}>*/}
        {/*  <iframe width="720" height="400" style={{margin: "auto"}}*/}
        {/*          src="https://www.youtube.com/embed/kFyLck7RUYo">*/}
        {/*  </iframe>*/}
        {/*</div>*/}
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
        </>
  );
};
