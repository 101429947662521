import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import openb from "../../images/openb.avif"
import openc from "../../images/openc.avif"
import opend from "../../images/opend.avif"
import opene from "../../images/opene.avif"

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        openb,
      subtitle: "Feature #1",
      title: "Team Ladder",
      description:
        "Coaches have the ability to make team ladders, so they can organize all their players into groups. The ladder is flexible because you can easily change the position as well as the team the player belongs to.",
    },

    {
      imageSrc:
        openc,
      subtitle: "Feature #2",
      title: "Matches",
      description:
        "Coaches also have the ability to create tennis matches for their team, so all players can see their match lineups against other players. Matches are separated into three subgroups for ease of organization.",
    },

    {
      imageSrc:
        opend,
      subtitle: "Feature #3",
      title: "Announcements",
      description:
      "You can also create and send announcements to your team. Once you create one, all players will automatically be sent a text message about your announcement.",
    },

    {
      imageSrc:
          opene,
      subtitle: "Feature #4",
      title: "Polls",
      description:
          "Lastly, coaches have the option to create a poll alongside their announcements. All visual data will be displayed in a chart, for ease of use.",
    }
  ];

  return (
    <Container id={"about"}>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>About OpenTennis</HeadingTitle>
          <HeadingDescription>
            OpenTennis is a completely FREE team management platform designed to help high school tennis coaches better manage their tennis team.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
             </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
