import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import bg from "../../images/opena.avif"

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url(${bg});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

export default () => {
  const navLinks = [
    <NavLinks key={2}>
      <PrimaryLink href="https://app.opentennis.pro/playerlogin" style={{fontSize: "inherit"}}>
        Login as player
      </PrimaryLink>
      <PrimaryLink style={{marginLeft: 15, fontSize: "inherit"}} href="https://app.opentennis.pro/coachlogin" >
        Login as coach
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading>
              A Team Management System for
              <br />
              Tennis Coaches and Players.
          </Heading>
          <PrimaryLink style={{marginTop: 25, fontSize: "inherit"}} href="https://app.opentennis.pro/coachlogin">
            Sign up now
          </PrimaryLink>
        </Content>
      </HeroContainer>
    </Container>
  );
};
