import React, {useState} from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg"
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg"
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg"
import mark from "../../videos/mark.mp4"
import raj from '../../videos/raj.mp4'
import markImg from '../../images/mark.png'
import rajx from '../../images/rajx.png'
import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div`mb-20`;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const VideoContainer = tw.div`flex flex-col justify-center lg:flex-row flex-wrap`
const TestimonialsSlider = `
  ${tw`flex mt-16 mx-auto max-w-xs sm:max-w-xl lg:max-w-4xl text-left bg-gray-100 rounded-lg text-gray-900`}
`;
const Testimonial = tw.div`px-6 py-2 sm:px-20 sm:py-2 focus:outline-none flex! flex-col! justify-between h-full`
const QuoteContainer = tw.div`relative`
const QuoteIcon = tw(QuoteIconBase)`absolute opacity-15 top-0 left-0 transform -translate-y-2 -translate-x-1/2 sm:-translate-x-full w-10 fill-current text-primary-500`
const Quote = tw.blockquote`font-medium sm:font-normal relative text-sm sm:text-xl text-center sm:text-left`
const CustomerInfoAndControlsContainer = tw.div`mt-4 flex items-center flex flex-col sm:flex-row justify-center text-center sm:text-left`
const CustomerImage = tw.img`w-20 h-20 rounded-full m-auto mb-2`
const CustomerNameAndProfileContainer = tw.div`py-2 sm:mt-0 sm:ml-4 flex flex-col text-center m-auto`
const CustomerName = tw.h2`text-xl font-bold`
const CustomerProfile = tw.h3`text-sm font-semibold`
const ControlsContainer = tw.div`sm:ml-auto flex`
const ControlButton = styled.button`
  ${tw`text-gray-600 hover:text-primary-700 focus:outline-none transition-colors duration-300 ml-4 first:ml-0 sm:first:ml-4 mt-4 sm:mt-0`}
  .icon {
    ${tw`fill-current w-6`}
  }
`;

export default ({
  subheading = "",
  heading = "Testimonials",
  description = "Here are what some of our high school coaches are saying about our product.",
}) => {
  const [sliderRef, setSliderRef] = useState(null)

  return (
    <PrimaryBackgroundContainer id={"testimonials"}>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>

        <VideoContainer>

          <div>
        <CustomerNameAndProfileContainer>
          <CustomerImage src={markImg} />
          <CustomerName>
            Mark Stephenson
          </CustomerName>
          <CustomerProfile>
            Amador Valley High School, Varsity Boys Coach
          </CustomerProfile>
        </CustomerNameAndProfileContainer>

        <Testimonial>
          <CustomerInfoAndControlsContainer>
            <video width="380" controls >
              <source src={mark} type="video/mp4"/>
            </video>
          </CustomerInfoAndControlsContainer>
        </Testimonial>
          </div>

          <div>
            <CustomerNameAndProfileContainer>
              <CustomerImage src={rajx} />
              <CustomerName>
                Raj Sabhlok
              </CustomerName>
              <CustomerProfile>
                Amador Valley High School, Junior Varsity Boys Coach
              </CustomerProfile>
            </CustomerNameAndProfileContainer>

            <Testimonial>
              <CustomerInfoAndControlsContainer>
                <video width="380" controls >
                  <source src={raj} type="video/mp4"/>
                </video>
              </CustomerInfoAndControlsContainer>
            </Testimonial>
          </div>


        </VideoContainer>




      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
