import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, {useState} from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are custom building it */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

import Footer from "components/footers/MiniCenteredFooter.js";

import AnimationRevealPage from "./helpers/AnimationRevealPage";
import Hero from "./components/hero/BackgroundAsImageWithCenteredContent";
import Features from "./components/features/VerticalWithAlternateImageAndText";
import Blog from "./components/blogs/ThreeColSimpleWithImage";
import ReactModal from "react-modal";
import x from "./images/x.svg"

export default function App() {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;

    const [open,setOpen]= useState(false)
    const [img,setImg] = useState("")

  return (
      <>
          <AnimationRevealPage>
            <Hero />
            <Features />
            <Testimonial />
            <Blog open={open} setOpen={setOpen} setImg={setImg} />
            <Footer />
          </AnimationRevealPage>


          <ReactModal isOpen={open}>
              <button onClick={() => setOpen(false)}>
                  <img src={x} alt={"Close"} style={{marginBottom: 10}} />
              </button>
              <img src={img} alt={"loading..."} />
          </ReactModal>
      </>
  );
}

//<Router>
//       <Switch>
//         <Route path="/components/:type/:subtype/:name">
//           <ComponentRenderer />
//         </Route>
//         <Route path="/components/:type/:name">
//           <ComponentRenderer />
//         </Route>
//         <Route path="/">
//           <MainLandingPage />
//         </Route>
//       </Switch>
//     </Router>

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
